<template>
  <div class="expande-horizontal wrap pa-3 pl-1 pt-0 pb-0 pr-0 fonte">
    <v-flex xs12>
      <v-text-field
        dense
        filled
        append-inner-icon="mdi-magnify"
        label="Faça uma busca..."
        v-model="value.search"
        hide-details
        :color="$theme.primary"
        @input="updateList"
      ></v-text-field>
    </v-flex>
    <v-flex xs12>
      <div class="expande-horizontal">
        <v-btn :text="value.permission != permission._id" @click="setPermissionFilter(permission._id)" class="ma-1" v-for="permission in permissions" :key="permission._id">{{ permission.nome }}</v-btn>
      </div>
    </v-flex>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: ["value"],
  methods: {
    updateList() {
      this.$emit("input", this.value);
    },
    setPermissionFilter(permission_id) {
      this.value.permission = permission_id
      this.updateList();
    }
  },
  computed: {
    ...mapGetters(['get_permissions']),
    permissions() {
      return this.get_permissions.docs || []
    }
  }
};
</script>
